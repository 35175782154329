import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import { UserContext } from '../App';
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import AnimateWrapper from './AnimateWrapper';
import img1 from '../images/P2_20 1.png';
import img2 from '../images/Checkthismoment.png'
function FirstPage() {
    const { setPageState } = useContext(UserContext);
    function skip() {
        setPageState('gridPage');
    }
    return (
        <AnimateWrapper className='bg-theme-1 h-full relative flex justify-center items-center'
        >
            <div className='absolute left-12 top-12 !h-auto'>
                <button className='cta-btn bg-cta-2 text-theme-1 w-[170px] relative text-left font-bold pl-8 text-[17px] leading-[24px] px-2 py-3' onClick={skip}>
                    跳過
                    <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-5 w-5 h-5 group-hover:translate-x-2 transition-all' />
                </button>
            </div>
            <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src="images/promessa_logo 1.png" />
            <div className='flex items-center'>
                <img className='max-w-[610px]' src={img1} />
                <div className='max-w-[385px] text-center -ml-[25px]'>
                    <img className='max-w-[338px]' src={img2} />
                    <h3 className='title mb-[30px]'>性格反映小測試</h3>
                    <h5 className='description'>面對重要決擇的時刻，總會對如何選擇感到迷茫。不如在決定之前，更了解清楚自己的性格，才知道真正想要的是什麼！</h5>
                    <button className='cta-btn mt-[100px] text-cta border-cta w-[200px] relative text-left pl-10 group' onClick={() => setPageState('second')}>
                        開始測驗
                        <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-7 w-5 h-5 group-hover:translate-x-2 transition-all' />
                    </button>
                </div>
            </div>

        </AnimateWrapper>
    )
}

export default FirstPage