import { motion } from 'framer-motion'
import React from 'react'

function AnimateWrapper({children, className}) {
    return (
        <motion.div
            className={`h-screen ${className}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            {children}
        </motion.div>
    )
}

export default AnimateWrapper