export const questionList = [
    {
        question: '你覺得拍施的先決條件是？',
        answer: [
            '門當戶對',
            '三觀一致',
            '感情深厚'
        ]
    },
    {
        question: '去旅行之前，你會怎麼為行程準備？',
        answer: [
            '計劃好每日的行程表',
            '只選擇想去的目的地，即興遊玩',
            '不會錯過限時優惠，立即起程任何地方'
        ]
    },
    {
        question: '你更希望在哪裡約會？',
        answer: [
            '遊樂場',
            'DIY工藝坊',
            '運動場所'
        ]
    }
]

export const result = {
    independent: [
        ['門當戶對', '計劃好每日的行程表', '遊樂場'],
        ['門當戶對', '計劃好每日的行程表', 'DIY工藝坊'],
        ['門當戶對', '計劃好每日的行程表', '運動場所'],
        ['三觀一致', '計劃好每日的行程表', '遊樂場'],
        ['三觀一致', '計劃好每日的行程表', 'DIY工藝坊'],
        ['三觀一致', '計劃好每日的行程表', '運動場所'],
        ['門當戶對', '只選擇想去的目的地，即興遊玩', '遊樂場'],
        ['門當戶對', '只選擇想去的目的地，即興遊玩', 'DIY工藝坊'],
        ['門當戶對', '只選擇想去的目的地，即興遊玩', '運動場所'],
    ],
    open: [
        ['感情深厚', '計劃好每日的行程表', '遊樂場'],
        ['感情深厚', '計劃好每日的行程表', 'DIY工藝坊'],
        ['感情深厚', '計劃好每日的行程表', '運動場所'],
        ['三觀一致', '只選擇想去的目的地，即興遊玩', '遊樂場'],
        ['三觀一致', '只選擇想去的目的地，即興遊玩', 'DIY工藝坊'],
        ['三觀一致', '只選擇想去的目的地，即興遊玩', '運動場所'],
        ['感情深厚', '只選擇想去的目的地，即興遊玩', '遊樂場'],
        ['感情深厚', '只選擇想去的目的地，即興遊玩', 'DIY工藝坊'],
        ['感情深厚', '只選擇想去的目的地，即興遊玩', '運動場所'],

    ],
    free: [
        ['門當戶對', '不會錯過限時優惠，立即起程任何地方', '遊樂場'],
        ['門當戶對', '不會錯過限時優惠，立即起程任何地方', 'DIY工藝坊'],
        ['門當戶對', '不會錯過限時優惠，立即起程任何地方', '運動場所'],
        ['三觀一致', '不會錯過限時優惠，立即起程任何地方', '遊樂場'],
        ['三觀一致', '不會錯過限時優惠，立即起程任何地方', 'DIY工藝坊'],
        ['三觀一致', '不會錯過限時優惠，立即起程任何地方', '運動場所'],
        ['感情深厚', '不會錯過限時優惠，立即起程任何地方', '遊樂場'],
        ['感情深厚', '不會錯過限時優惠，立即起程任何地方', 'DIY工藝坊'],
        ['感情深厚', '不會錯過限時優惠，立即起程任何地方', '運動場所'],
    ]
}

export const types = {
    independent: {
        name: '獨立有主見型',
        leading_1: '享受DIY客製鑽戒的樂趣',
        leading_2: '透過 Promessa Checkthismoment 測試來獲取你的完美鑽戒！',
        image: 'images/independent.png',
        keyword: '獨立、有想法',
        description: '你的性格自主獨立，做事不僅有主見、有想法，如果下定決心去做某樣事情，一定會堅持到底。你的脾氣溫和有禮、做事不慌不忙。'
    },
    open: {
        name: '開放思想型',
        leading_1: '享受DIY客製鑽戒的樂趣',
        leading_2: '透過 Promessa Checkthismoment 測試來獲取你的完美鑽戒！',
        image: 'images/open.png',
        keyword: '熱情、開放自由',
        description: '你的性格熱愛自由、愛冒險和刺激，也喜歡美食和旅行。你熱情開朗，對朋友很講義氣，朋友們有心事都願意向你傾訴。'
    },
    free: {
        name: '自由思考型',
        leading_1: '享受DIY客製鑽戒的樂趣',
        leading_2: '透過 Promessa Checkthismoment 測試來獲取你的完美鑽戒！',
        image: 'images/free.png',
        keyword: '隨性、直接',
        description: '你的性格灑脫隨性，做事不喜歡被束縛。個性爽朗、說話直接，不愛耍心機，更討厭做事當面說一套，背後做一套的人。'
    },
}

export const gridData = [
    {
        id: 1,
        link: 'css4c://',
        img: './images/grid-1.png',
        text: '了解鑽石知識',
        gif: './images/2.gif',
        innerText: '不同克拉鑽石的準確尺寸'

    },
    {
        id: 2,
        link: 'https://cdn.chowsangsang.com/video2nas/CSS-4C-HK_Full_subtitle.mp4',
        img: './images/grid-2.png',
        text: '周生生挑選美鑽的原則',
        gif: './images/3.gif',
        innerText: '色澤由D到K的顏色'
    },
    {
        id: 3,
        link: 'mond://',
        img: './images/grid-3.png',
        text: '突破性車工',
        gif: './images/1_2.gif',
        innerText: '車工會影響鑽石的閃亮度'
    },
    {
        id: 4,
        link: '獨立有主見型',
        img: './images/grid-4.png',
        text: '立即訂製',
        gif: './images/4.gif',
        innerText: '不同的鑽戒款式'
    },
    {
        id: 5,
        img: './images/Promessa-4-12.png',
        text: '你知道鑽戒款式能使鑽石與眾不同嗎?',
        gif: './images/4.gif',
        innerText: '不同克拉鑽石的準確尺寸'
    }
]