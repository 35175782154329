import { preloadImage } from "@remotion/preload";
import React, { useState, createContext, useEffect } from 'react';
import DIYSelection from './components/DIYSelection';
import FirstPage from './components/FirstPage';
import FormPage from './components/FormPage';
import GridPage from "./components/GridPage";
import ResultPage from './components/ResultPage';
import SecondPage from './components/SecondPage';
import VideoPage from "./components/VideoPage";
import img1 from './images/Checkthismoment.png'
import img2 from './images/diamond_radius_button.png'
import img3 from './images/free.png'
import img4 from './images/independent.png'
import img5 from './images/l_ring.png'
import img6 from './images/multi_right.png'
import img7 from './images/open.png'
import img8 from './images/P2_20 1.png'
import img9 from './images/promessa_logo 1.png'
import img10 from './images/r_diamond.png'
import img11 from './images/single_left.png'


export const UserContext = createContext();

const App = () => {
  const imageList = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]
  const [pageState, setPageState] = useState('video');
  const [videoPageState, setVideoPageState] = useState(1);
  const [formData, setFormData] = useState([
    {
        question1: '',
        question2: '',
        question3: ''
    },
    {
        question_1: '',
        question_2: '',
        question_3: ''
    }
  ]);
  function preload_image (src) {
    preloadImage(src)
  }
  useEffect(() => {
    imageList.forEach((image) => {
      preload_image(image)
    })
  }, [])

  return (
    <UserContext.Provider value={{ videoPageState, setVideoPageState, pageState, setPageState, formData, setFormData }} >
      <div className="page-container">
        {pageState === 'video' && (
          <VideoPage />
        )}
        {pageState === 'first' && (
          <FirstPage />
        )}
        {pageState === 'second' && (
          <SecondPage />
        )}
        {pageState === 'singleForm' && (
          <FormPage isSingle={true} />
        )}
        {pageState === 'coupleForm' && (
          <FormPage isSingle={false} />
        )}
        {pageState === 'singleFormResult' && (
          <ResultPage isSingle={true} />
        )}
        {pageState === 'coupleFormResult' && (
          <ResultPage isSingle={false} />
        )}
        {pageState === 'gridPage' && (
          <GridPage />
        )}
        {pageState === 'DIYSelection' && (
          <DIYSelection />
        )}

      </div>


    </UserContext.Provider>

  )
};

export default App;
