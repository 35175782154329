import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../App';
import { result } from './Data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimateWrapper from './AnimateWrapper';
import Result from './Result';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';


function PrevArrow ({  onClick, currentSlide, slideCount }) {
    return (
        <button disabled={currentSlide === 0} className={`absolute top-1/2 -translate-y-1/2 left-8 w-20 h-20 bg-white rounded-full flex justify-center items-center z-10 disabled:opacity-0`} onClick={onClick}>
            <ChevronLeftIcon className="fill-orange-theme-2 w-8 h-8" />
        </button>
    )
}

function NextArrow ({  onClick, currentSlide, slideCount }) {
    return (
        <button disabled={currentSlide === slideCount - 1} className={`absolute top-1/2 -translate-y-1/2 right-8 w-20 h-20 bg-white rounded-full flex justify-center items-center z-10 disabled:opacity-0`} onClick={onClick}>
            <ChevronRightIcon className="fill-orange-theme-2 w-8 h-8" />
        </button>
    )
}


function ResultPage({ isSingle }) {
    const [keyData, setKeyData] = useState([])
    const { setPageState, formData, setFormData } = useContext(UserContext);
    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: keyData.length > 1 && <NextArrow />,
        prevArrow: keyData.length > 1 && <PrevArrow />
    };
    function getResultKeys() {
        let resultArr = []
        formData.forEach((data, formIndex) => {
            for (const [resultName, pairs] of Object.entries(result)) {
                pairs.forEach((pair, index) => {
                    if (data[`question${formIndex === 0 ? '' : '_'}1`] === pair[0] && data[`question${formIndex === 0 ? '' : '_'}2`] === pair[1] && data[`question${formIndex === 0 ? '' : '_'}3`] === pair[2]) {
                        resultArr.push(resultName)
                    }
                })
            }
        })
        setKeyData(resultArr)

    }
    useEffect(() => {
        getResultKeys()
        setFormData([
            {
                question1: '',
                question2: '',
                question3: ''
            },
            {
                question_1: '',
                question_2: '',
                question_3: ''
            }
        ])
    }, [])


    return (
        <AnimateWrapper>
            <Slider {...settings}>
                {keyData?.map((item, index) => {
                    return (
                        <AnimateWrapper key={index}>
                            <Result isCouple={keyData.length > 1} isFirst={index === 0} type={item} />
                        </AnimateWrapper>
                    )
                })}
            </Slider>
            <button type="button" className='absolute-btn cta-btn cta-txt text-cta border-cta w-[200px] relative text-center' onClick={() => setPageState('gridPage')}>下一步</button>
        </AnimateWrapper>
    )
}

export default ResultPage