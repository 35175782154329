import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { preloadVideo } from '@remotion/preload'
import React, { useContext, useEffect, useState } from 'react'
import { useRef } from 'react';
import { UserContext } from '../App'
import img1 from '../images/P2_20 1.png';
import video1 from '../videos/P1-2.mp4'
import video2 from '../videos/P3.mp4'
import video3 from '../videos/P4.mp4'
import video4 from '../videos/P5.mp4'
import video5 from '../videos/P6-8.mp4'
import video6 from '../videos/P9-12.mp4'
import AnimateWrapper from './AnimateWrapper'

function VideoPage() {
    const { videoPageState, setVideoPageState, setPageState } = useContext(UserContext);
    const [isVideoFirstPage, setIsVideoFirstPage] = useState(true);
    const [isVideoEnd, setisVideoEnd] = useState(false)
    /* const videoList = [video1, video2, video3, video4, video5, video6] */
    const videoList = ['/videos/P1-2.mp4', '/videos/P3.mp4', '/videos/P4.mp4', '/videos/P5.mp4', '/videos/P6-8.mp4', '/videos/P9-12.mp4']
    const containerRef = useRef(null);
    let startX = null;
    let startY = null;
    let currentX = null;
    let currentY = null;

    const handleTouchStart = (event) => {
        startX = event.touches[0].clientX;
        startY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        currentX = event.touches[0].clientX;
        currentY = event.touches[0].clientY;
    };

    const handleTouchEnd = () => {
        const deltaX = currentX - startX;
        const deltaY = currentY - startY;
        console.log(startX);
        console.log(currentX);
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            if (deltaX > 0 && currentX !== null) {
                console.log(`prev >>>>> ${deltaX}`)
                if (videoPageState !== 1) {
                    prevStep()
                }

            } else if (deltaX < 0 && currentX !== null) {
                if (videoPageState === videoList.length) {
                    // setPageState('first')
                    setPageState('gridPage');
                } else {
                    console.log(`next >>>>> ${deltaX}`)
                    nextStep()
                }
            }
        }
    };

    function preload_video(src) {
        preloadVideo(src)
    }
    function onVideoEnd() {
        setisVideoEnd(true)
    }
    function nextStep() {
        setVideoPageState(prev => prev + 1)
        setisVideoEnd(false)
    }
    function prevStep() {
        setVideoPageState(prev => prev - 1)
        setisVideoEnd(false)
    }
    function init() {
        setIsVideoFirstPage(true)
        setVideoPageState(1)
    }
    function skip() {
        setPageState('first');
        setVideoPageState(1)
    }
    function gridPage() {
        setPageState('gridPage');
    }
    useEffect(() => {
        videoList.forEach((src) => {
            preload_video(src)
        })

    }, [])


    return (
        <div className='bg-theme-1 h-full relative flex justify-center items-center'>
            {isVideoFirstPage ? (
                <AnimateWrapper className="relative">
                    <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[688px]" src={img1} />
                    <button className='absolute-btn cta-btn bg-cta-2 text-theme-1 w-[200px] relative text-left font-bold pl-10 text-[20px] leading-[24px]' onClick={() => setIsVideoFirstPage(false)}>
                        開始
                        <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-9 w-5 h-5 group-hover:translate-x-2 transition-all' />
                    </button>
                </AnimateWrapper>
            ) : (
                <AnimateWrapper className="h-auto">
                    <div
                        ref={containerRef}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <video autoPlay muted onEnded={() => onVideoEnd()} src={videoList[videoPageState - 1]}></video>
                        {/* {isVideoEnd && (
                            <AnimateWrapper className='absolute right-12 top-12 h-auto'>
                                <button className='cta-btn bg-cta-2 text-theme-1 w-[200px] relative text-left font-bold pl-10 text-[20px] leading-[24px]' onClick={videoPageState === videoList.length ? () => setPageState('first') : nextStep}>
                                    繼續
                                    <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-9 w-5 h-5 group-hover:translate-x-2 transition-all' />
                                </button>
                            </AnimateWrapper>
                        )} */}
                        <AnimateWrapper className='absolute left-12 top-12 !h-auto'>
                            <button className='cta-btn bg-cta-2 text-theme-1 w-[170px] relative text-left font-bold pl-8 text-[17px] leading-[24px] px-2 py-3' onClick={gridPage}>
                                跳過
                                <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-5 w-5 h-5 group-hover:translate-x-2 transition-all' />
                            </button>
                        </AnimateWrapper>
                    </div>

                    {/* <AnimateWrapper className='absolute left-12 top-36 !h-auto'>
                        <button className='cta-btn bg-cta-2 text-theme-1 w-[200px] relative text-left font-bold pl-10 text-[20px] leading-[24px]' onClick={init}>
                            init
                            <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-9 w-5 h-5 group-hover:translate-x-2 transition-all' />
                        </button>
                    </AnimateWrapper> */}
                </AnimateWrapper>
            )}


        </div>
    )
}

export default VideoPage