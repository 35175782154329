import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import { questionList } from './Data';
import { FormContext } from './FormPage';
import img1 from '../images/diamond_radius_button.png';
function Form({ isFirst, stepNum }) {
    const { formData, handleChangeSingle, handleChangeCouple } = useContext(FormContext);

    return (
        <>
            <span className="cta-txt text-black-theme mb-6 text-center block">{stepNum} / {questionList.length}</span>
            <h2 className="subtitle text-black-theme mb-16 text-center">{questionList[stepNum - 1].question}</h2>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className='w-full flex flex-col gap-6'
            >
                {questionList[stepNum - 1].answer.map((ans, index) => (
                    <div className="" key={index}>
                        <input
                            className='radio-btn invisible hidden'
                            id={`question${isFirst ? '': '_'}${index+1}`}
                            type="radio"
                            name={`question${isFirst ? '': '_'}${stepNum}`}
                            value={ans}
                            checked={formData[isFirst ? 0 : 1][`question${isFirst ? '': '_'}${stepNum}`] === ans}
                            onChange={isFirst ? handleChangeSingle: handleChangeCouple}
                        />
                        <label className='radio-label' htmlFor={`question${isFirst ? '': '_'}${index+1}`}>
                            <img className='w-8 h-8 absolute top-1/2 -translate-y-1/2 left-5 opacity-60' src={img1} />
                            <span className='block cta-txt text-black-theme text-center'>{ans}</span>

                        </label>
                    </div>
                ))}
            </motion.div>
        </>

    )
}

export default Form