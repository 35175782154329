import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from '../App';
import AnimateWrapper from './AnimateWrapper'
import { gridData } from './Data';

function GridPage() {
    const { setPageState } = useContext(UserContext);
    const [selectedId, setSelectedId] = useState(null)
    const handleOnClick = (index, link) => {
        setSelectedId(index)
        if (index < 3) {
            window.location = link;
        }
    }

    return (
        <div className='bg-theme-3 h-full relative flex justify-center items-center'>
            <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src="images/promessa_logo 1.png" />
            <div className='grid grid-cols-2 grid-rows-2 w-full h-screen relative'>
                {gridData.map((item, index) => {
                    let hover;
                    if (index == 3 && selectedId == 3) {
                        hover = <div className={'absolute w-full h-full bg-[#F47A5C99]'}>
                                <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                                    <a href="https://hksolitaire.chowsangsang.com/tc/"><div className="m-5 bg-white text-center w-48 py-3 rounded-3xl text-[#D0865F]">鑽飾訂製</div></a>
                                    <a href="https://hkpromessadiy.chowsangsang.com/diy/"><div className="m-5 bg-white text-center w-48 py-3 rounded-3xl text-[#D0865F]">對戒訂製</div></a>
                                </div>
                            </div>;
                    }
                    return (
                        <div key={index} className={`relative flex justify-center items-center flex-col ${(index === 0 || index === 3) ? 'bg-theme-4' : ''} ${index === 4 ? 'hidden' : ''}`} onClick={() => handleOnClick(index, item.link)}>

                            <img className="block max-w-[250px] mt-[40px]" src={item.img} alt={item.img} ></img>
                            <div className='relative bottom-[26px] text-[#D0865F] text-[16px] border-[1px] border-[#D0865F] py-3 px-4 rounded-full'>{item.text}
                            </div>

                            {hover}

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GridPage