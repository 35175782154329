import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import { UserContext } from '../App';
import AnimateWrapper from './AnimateWrapper';
import img1 from '../images/promessa_logo 1.png';
import img2 from '../images/l_ring.png';
import img3 from '../images/r_diamond.png';

function DiySelection() {
    const { setPageState } = useContext(UserContext);
    return (
        <AnimateWrapper className="flex-container h-full relative">
            <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src={img1} />

            <div className='left bg-theme-2 flex flex-col justify-center items-center h-full'>
                <img className="w-full" src={img2} />
                <a href="https://hksolitaire.chowsangsang.com/tc/" target="_blank" className='cta-btn text-cta border-cta w-[200px] relative text-left group'>
                    戒指款式
                    <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-7 w-5 h-5 group-hover:translate-x-2 transition-all' />
                </a>
            </div>
            <div className='right bg-theme-3 flex flex-col justify-center items-center h-full'>
                <img className="w-full" src={img3} />
                <a href="https://hkpromessadiy.chowsangsang.com/diy/" target="_blank" className='cta-btn text-cta border-cta w-[200px] relative text-left group'>
                    自選鑽石
                    <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-7 w-5 h-5 group-hover:translate-x-2 transition-all' />
                </a>
            </div>
            <button type="button" className='absolute-btn cta-btn cta-txt text-cta border-cta w-[200px] relative text-center' onClick={() => setPageState('video')}>返回首頁</button>
        </AnimateWrapper>
    )
}

export default DiySelection