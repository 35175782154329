import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import { UserContext } from '../App';
import img1 from '../images/promessa_logo 1.png';
import img2 from '../images/single_left.png';
import img3 from '../images/multi_right.png';

function SecondPage() {
    const { setPageState } = useContext(UserContext);
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className='flex-container h-full relative'>
            <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src={img1} />

            <div className='left bg-theme-2 flex flex-col justify-center items-center h-full'>
                <img className="w-full" src={img2} />
                <h2 className="title text-brown-theme">單人</h2>
                <button className='cta-btn mt-[100px] text-cta border-cta w-[200px] relative text-left group pl-10' onClick={() => setPageState('singleForm')}>
                    開始測驗
                    <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-7 w-5 h-5 group-hover:translate-x-2 transition-all' />
                </button>
            </div>
            <div className='right bg-theme-3 flex flex-col justify-center items-center h-full'>
                <img className="w-full" src={img3} />
                <h2 className="title text-brown-theme">雙人</h2>
                <button className='cta-btn mt-[100px] text-cta border-cta w-[200px] relative text-left group pl-10' onClick={() => setPageState('coupleForm')}>
                    開始測驗
                    <ArrowRightIcon className='absolute top-1/2 -translate-y-1/2 right-7 w-5 h-5 group-hover:translate-x-2 transition-all' />
                </button>
            </div>
        </motion.div>

    )
}

export default SecondPage