import React from 'react'
import { types } from './Data'
import img1 from '../images/promessa_logo 1.png';

function Result({ isCouple, isFirst, type }) {
    return (
        <div className='bg-theme-1 h-full relative flex justify-center items-center'
        >
            <div className='absolute w-[1270px] h-[1270px] rounded-full bg-white left-1/2 -translate-x-1/2 -bottom-[113%]'></div>
            <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src={img1} />
            <div className='flex flex-col items-center max-w-[512px] mx-auto z-10'>

                <span className='cta-txt text-orange-theme-2 mb-5'>
                    {isCouple && isFirst && '第一位客人'}
                    {isCouple && !isFirst && '第二位客人'}
                    {!isCouple && isFirst && '客人'}
                </span>

                <div className='mb-8 flex items-baseline gap-3'>
                    <span className='subtitle'>你是屬於 </span>
                    <span className='title text-brown-theme'>{types[type].name}</span>
                </div>
                <p className='cta-txt text-black-theme text-center'>享受DIY客製鑽戒的樂趣，<br/>透過 Promessa Checkthismoment 測試來獲取你的完美鑽戒！</p>
                <img className='block max-w-[220px] mb-4' src={types[type].image} />
                <h3 className='title mb-4'>{types[type].keyword}</h3>
                <p className='cta-txt text-black-theme text-center mb-12'>{types[type].description}</p>
            </div>

        </div>
    )
}

export default Result