import { motion } from 'framer-motion';
import React, { useContext, useState, createContext } from 'react'
import { UserContext } from '../App';
import Form from './Form';
import img1 from '../images/promessa_logo 1.png';

export const FormContext = createContext();

function FormPage({ isSingle }) {

    const { setPageState, formData, setFormData } = useContext(UserContext);

    const [step, setStep] = useState(1);
    const [error, setError] = useState(false);

    const handleChangeSingle = event => {
        const newFormData = [...formData];
        newFormData[0] = {
            ...newFormData[0],
            [event.target.name]: event.target.value,
        };

        setFormData(newFormData);
    };
    const handleChangeCouple = event => {
        const newFormData = [...formData];
        newFormData[1] = {
            ...newFormData[1],
            [event.target.name]: event.target.value,
        };

        setFormData(newFormData);
    };

    const handleSubmitSingle = event => {
        event.preventDefault();
        if (formData[0][`question${step}`]) {
            console.log(formData);
            setError(false)
            console.log(step);
            if (step === 3) {

                setPageState('singleFormResult')

            } else {
                setStep(prev => prev + 1);

            }

        } else {
            setError(true)
        }

    };
    const handleSubmitCouple = event => {
        event.preventDefault();
        console.log(formData);
        if (formData[0][`question${step}`] && formData[1][`question_${step}`]) {
            setError(false)
            console.log(step);
            if (step === 3) {
                setPageState('coupleFormResult')

            } else {
                setStep(prev => prev + 1);
            }

        } else {
            console.log(formData);
            setError(true)
        }

    };
    const backStep = () => {
        setStep(prev => prev - 1)
    }
    return (
        <FormContext.Provider value={{ formData, handleChangeSingle, handleChangeCouple }}>
            {isSingle ? (
                <div className='bg-theme-1 h-full relative flex justify-center items-center'
                >
                    <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src={img1} />
                    <div className="w-full max-w-[600px] mx-auto">
                        {step === 1 && (
                            <Form isFirst={true} stepNum={1} />
                        )}
                        {step === 2 && (
                            <Form isFirst={true} stepNum={2} />

                        )}
                        {step === 3 && (
                            <Form isFirst={true} stepNum={3} />

                        )}
                    </div>

                    {error && <p className='absolute-error text-red-600'>請選擇</p>}
                    {step > 1 && (
                        <button className='absolute-btn absolute-btn__back' type="button" onClick={backStep}>上一題</button>
                    )}
                    <button type="button" className='absolute-btn cta-btn cta-txt text-cta border-cta w-[200px] relative text-center' onClick={handleSubmitSingle}>{step > 2 ? '提交' : '下一題'}</button>

                </div>
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className='flex-container h-full relative'>
                    <img className="max-w-[430px] absolute left-1/2 -translate-x-1/2 top-[50px] z-10" src={img1} />

                    <div className='left bg-theme-2 flex flex-col justify-center items-center h-full'>
                        <span className="description text-orange-theme absolute left-1/2 -translate-x-1/2 bottom-9">第一位客人</span>
                        <div className="w-full max-w-[550px] px-5">
                            {step === 1 && (
                                <Form isFirst={true} stepNum={1} />

                            )}
                            {step === 2 && (
                                <Form isFirst={true} stepNum={2} />

                            )}
                            {step === 3 && (
                                <Form isFirst={true} stepNum={3} />

                            )}
                        </div>

                    </div>
                    <div className='right bg-theme-3 flex flex-col justify-center items-center h-full'>
                        <span className="description text-orange-theme absolute left-1/2 -translate-x-1/2 bottom-9">第二位客人</span>
                        <div className="w-full max-w-[550px] px-5">
                            {step === 1 && (
                                <Form isFirst={false} stepNum={1} />
                            )}
                            {step === 2 && (
                                <Form isFirst={false} stepNum={2} />
                            )}
                            {step === 3 && (
                                <Form isFirst={false} stepNum={3} />
                            )}
                        </div>


                    </div>
                    {error && <p className='absolute-error text-red-600'>請選擇</p>}
                    {step > 1 && (
                        <button className='absolute-btn absolute-btn__back' type="button" onClick={backStep}>上一題</button>
                    )}
                    <button type="button" className='absolute-btn cta-btn cta-txt text-cta border-cta w-[200px] relative text-center' onClick={handleSubmitCouple}>{step > 2 ? '提交' : '下一題'}</button>
                </motion.div>

            )}
        </FormContext.Provider>
    )
}

export default FormPage